.landingContainer {
  box-shadow: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: 10px;
}
.lndHead {
    font-size: 3.9vw;

}


.headers {
  font-size: calc(64px);
  font-weight: 600;
  font-family: "Inter", sans-serif;
  text-justify: inter-word;
  text-align: start;
  color: #9eb5cd;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 110.023%;
  letter-spacing: 25.86px;
  padding-bottom: 1em !important;
}

.headerRow{
  width: 60%;
}

.infoRow {
  align-items: center;
}

.nextToParagraphs{
  display: flex;
  justify-content: center;
}

.paragraphs {
  font-size: 16px;
  font-weight: lighter;
  font-family: "Inter", sans-serif;
  text-align: justify;
  text-justify: inter-word;
  line-height: 200%;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3.9vw;
  color: white;
  background-image: url("../pictures/Landing/lines.jpg");
  background-size: cover;
  background-position: center;
  font-weight: 600;
  width: 100%;
}

.paragraphPadding {
  padding-top: 50px;
}

.columnImage {
  width: 75%;
}

.buttonContainer {
  position: relative;
  background-color: #d9d9d9;
  margin: 20px;
  width: 100%;
  height: 100%;
}

.exploreOurWorkButton {
  height: 50px;
  width: 33%;
}

.outerButtonContainer{
  display: flex;
  justify-content: space-around;
  padding: 3em !important;
}

.inner {
  width: 100%;
  height: 50px;
  position: absolute;
  border: 2px solid black;
  bottom: -5px;
  right: -5px;
  z-index: 10;
  justify-content: center;
  display: flex;
  align-items: center;
}

.aboutUs {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-size: cover;
  background-position: bottom;
  padding-top: 3em !important;
}

.merinda {
  border-radius: 60px 0px 0px 60px;
  width: 75%;
}

.ian {
  border-radius: 0px 60px 60px 0px;
  width: 75%;
}

.portraits {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-size: cover;
  background-position: bottom;
}

.who{
  width: 45% !important;
}

@media only screen and (max-width: 1280px) {
      .App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3.9vw;
  color: white;
  background-image: url("../pictures/Landing/lines.jpg");
  background-size:cover;
  background-position: center;
  font-weight: 600;
  width: 100%;
}
  .infoRow {
  display: block !important;
}
.nextToParagraphs{
      margin-bottom: 3em;
}
  .paragraphs, .nextToParagraphs{
    margin-bottom: 12px;
    padding-top: 12px;
  }

    .paragraphs{
   font-size: 16px;
   text-align:center;
  }
    .headers .col{
    margin-bottom: 12px;
    padding-top: 12px;
  }

  .who{
  width: 50% !important;
}

.buttonContainer {
  position: relative;
  background-color: #d9d9d9;
  margin: 20px;
  width: 100%;
  height: 100%;
}

.exploreOurWorkButton {
  height: 75px;
  width: 75%;
}

.outerButtonContainer{
  display: flex;
  justify-content: space-around;
  padding: 3em !important;
}

.inner {
  width: 100%;
  height: 73px;
  position: absolute;
  border: 2px solid black;
  bottom: -5px;
  right: -8px;
  z-index: 10;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 2.8vw
}
}

@media only screen and (max-width: 980px) {
    .App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3.9vw;
  color: white;
  background-image: url("../pictures/Landing/lines.jpg");
  background-size:cover;
  background-position: center;
  font-weight: 600;
  width: 100%;
}

  .who{
  text-align: center;
  width: 100% !important;
}

  .headers {
    width: 100%;
  font-size: 45px;
    justify-content: center;
  display: flex;
  text-align: center;

  }
  .headerRow{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
    .lndHead {
      font-size: 6vw;
      color: #f4f4f4;
      font-weight: 600;
      font-family: "Inter", sans-serif;
      text-align: center;
      text-justify: inter-word;
      overflow-wrap:normal;
  }
    .HeadParagraphs {
  font-size: 3.9vw !important;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    text-align: justify;
    text-justify: inter-word;
  }
}

@media only screen and (max-width: 600px) {
  .lndHead {
      font-size: 3.9vw;
      font-weight: 600;
      font-family: "Inter", sans-serif;
      text-align: justify;
      text-justify: inter-word;
      overflow-wrap:normal;
  }

  .paragraphPadding {
  min-width: 100%;
  padding-top: 12px;
}

  .aboutUs {
  margin-top: 12px;
  padding-top: 0 !important;
}

  .headers {
  font-size: calc(18px);
  font-weight: 600;
  font-family: "Inter", sans-serif;
  text-justify: inter-word;
  text-align: center;
  color: #9eb5cd;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 15.86px;
  justify-content: center;
  display: flex;
  padding-bottom: 0 !important;
}

.headerRow{
  min-width: 100%;
  margin-bottom: 12px;
    width: 100%;
}

.infoRow, .nextToParagraphs, .columnImage {
  min-width: 100%;
}

  .HeadParagraphs {
  font-size: 3.9vw !important;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    text-align: justify;
    text-justify: inter-word;
  }

  .App-header {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3.9vw;
  color: white;
  background-image: url("../pictures/Landing/lines.jpg");
  background-size:cover;
  background-position: center;
  font-weight: 700;
  width: 100%;
}

  .landingContainer {
    box-shadow: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 10px;
  }
  .merinda {
    border-radius: 60px 0px 0px 60px;
    width: 75%;
  }

  .ian {
    border-radius: 0px 60px 60px 0px;
    width: 75%;
  }

  .portraits {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    background-size: cover;
    background-position: bottom;
  }

  .paragraphs, .nextToParagraphs{
    margin-bottom: 12px;
    padding-top: 12px;
  }

  .outerButtonContainer{
  display: flex;
  justify-content: center;
  padding: 2em !important;
}

.buttonContainer {
  position: relative;
  background-color: #d9d9d9;
  margin: 0px;
  width: 100%;
  height: 100%;
}
}

@media only screen and (max-width: 426px) {
  .lndHead {
      font-size: 2.5rem;
      font-weight: 700;
    font-family: "Inter", sans-serif;
    text-align:center;
    text-justify: inter-word;
      overflow-wrap:normal;
  }

  .paragraphPadding {
  min-width: 100%;
  padding-top: 12px;
}

  .aboutUs {
  margin-top: 12px;
  padding-top: 0 !important;
}

  .headers {
  font-size: calc(18px);
  font-weight: 600;
  font-family: "Inter", sans-serif;
  text-justify: inter-word;
  text-align: center;
  color: #9eb5cd;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 15.86px;
  justify-content: center;
  display: flex;
  padding-bottom: 0 !important;
    min-width: 100%;
  width: 100%;
}

.headerRow{
  min-width: 100%;
  width: 100%;
  margin-bottom: 12px;
}

.infoRow, .nextToParagraphs, .columnImage {
  min-width: 100%;
}

 .HeadParagraphs {
      font-size: 4vw;
      transform: scale(1,1);
    font-weight: 600;
    font-family: "Inter", sans-serif;
    text-align: justify;
    text-justify: inter-word;
    text-align: center;
  }

  .App-header {
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vw !important;
  color: white;
  background-image: url("../pictures/Landing/lines.jpg");
  background-size:cover;
  background-position: center;
  font-weight: 700;
  width: 100%;
}

  .landingContainer {
    box-shadow: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 10px;
  }
  .merinda {
    border-radius: 60px 0px 0px 60px;
    width: 75%;
  }

  .ian {
    border-radius: 0px 60px 60px 0px;
    width: 75%;
  }

  .portraits {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    background-size: cover;
    background-position: bottom;
  }

  .paragraphs, .nextToParagraphs{
    margin-bottom: 12px;
    padding-top: 12px;
  }

  .outerButtonContainer{
  display: flex;
  justify-content: center;
  padding: 2em !important;
}

.buttonContainer {
  position: relative;
  background-color: #d9d9d9;
  margin: 0px;
  width: 100%;
  height: 100%;
}
}
